import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, InputGroup, FormControl, Modal } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Link } from "react-router-dom";
import { defaultOptions } from "../../config/index";
import { validateCodeAction, balanceGetAction } from "../../redux/actions";
import Captcha from "../CaptchaClick";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const SubmitCode = (props) => {
	const { t } = useTranslation();
	let dispatch = useDispatch();
	let [codelist, setCodeList] = useState([""]);
	let [codeStatusList, setcodeStatusList] = useState([]);
	let [showPopup, setShowPopup] = useState(false);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptcha, setResetCaptcha] = useState(0);
	const codeListUpdate = (ind) => {
		let codeTextFiled = [...codelist];
		if (ind === codeTextFiled.length - 1) {
			codeTextFiled.push("");
		} else {
			codeTextFiled.splice(ind, 1);
		}
		setCodeList(codeTextFiled);
	};
	const updateCodeList = (e, ind) => {
		let codeTextFiled = [...codelist];
		// if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
		codeTextFiled[ind] = e.target.value;
		// }
		setCodeList(codeTextFiled);
	};
	const resetComponent = () => {
		let tempresetCaptcha = resetCaptcha + 1;
		setResetCaptcha(tempresetCaptcha);
		setCodeList([""]);
		setcodeStatusList([]);
		setCaptchaValue(false);
	};
	const formSubmit = () => {
		let codeTextFiled = [...codelist];
		if (codeTextFiled[0] === "") {
			toast.error(`${t("Error")}! ${t("Please try again")}. ${t("Enter at least one code")}`);
			return false;
		}
		if (!captchaValue && window.location.hostname !== "test.userportal-terra.rsi-rnd.com") {
			toast.error(`${t("Error")}! ${t("Please select captcha")}`);
			return false;
		}
		let submitCodes = codeTextFiled.filter((v) => v !== "");
		props.setLoading(true);
		const formData = { packageCodes: submitCodes, captchaVal: captchaValue };
		validateCodeAction(formData)
			.then((responseData) => {
				if (responseData.httpCode === 200) {
					setcodeStatusList(responseData.data);
					dispatch(balanceGetAction());
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);
					console.log("responseData", responseData);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				toast.error(`${t("Error")}! ${t("Something went wrong")}`);
				props.setLoading(false);
				console.log("Response Error:", JSON.stringify(err));
			});
	};
	return (
		<Container fluid>
			<div className="validate-purchase-div">
				<Container fluid>
					<Row className="justify-content-md-center">
						<Col xs lg="6" className="text-center">
							<h2 className="h2-title">
								<Trans>DOES YOUR MOVIE HAVE A CODE</Trans>?
							</h2>
						</Col>
					</Row>
					<Row className="justify-content-md-center">
						<Col xs lg="6" className="text-center">
							<h2 className="h2-disc1">
								<Trans>Look inside your</Trans>
							</h2>
							<h2 className="h2-disc1">
								<Trans>DVD/Blu-ray/4K Ultra HD case</Trans>
							</h2>
						</Col>
					</Row>
					<Row className="justify-content-md-center">
						<Col xs lg="6" className="text-center">
							<label className="label-disc">
								<Trans>Make sure your code isn’t expired, and that it’s not for a digital copy</Trans>.
							</label>
						</Col>
					</Row>
					<Row className="justify-content-md-center">
						<Col xs lg="6" className="text-center">
							<label className="label-link" onClick={(e) => setShowPopup(true)}>
								<Trans>Still unsure</Trans>? <Trans>Click here to see the inserts</Trans>
							</label>
						</Col>
					</Row>
					{codeStatusList.length === 0 ? (
						<>
							<Row className="justify-content-md-center">
								<Col xs={12} md={6}>
									{codelist.map((v, i) => {
										return (
											<Row key={`row${i}`}>
												<Col xs={10} md={10}>
													<InputGroup className="mb-3">
														<InputGroup.Text id="basic-addon3">
															{" "}
															<Trans>Enter Code</Trans>{" "}
														</InputGroup.Text>
														<FormControl
															name={`code${i}`}
															aria-describedby="basic-addon3"
															placeholder=""
															onChange={(e) => {
																updateCodeList(e, i);
															}}
															value={v}
															maxLength={100}
															id={`enter-code-textbox-${i}`}
														/>
													</InputGroup>
												</Col>
												<Col xs={2} md={2}>
													<button
														type="button"
														onClick={(e) => {
															codeListUpdate(i);
														}}
														className="btn btn-primary"
														id={i === codelist.length - 1 ? `add-code-btn` : `del-code-btn-${i}`}>
														{i === codelist.length - 1 ? "+" : "-"}
													</button>
												</Col>
											</Row>
										);
									})}
								</Col>
							</Row>
							<Row className="justify-content-md-center">
								<Col xs lg="6" className="text-center">
									<Captcha reset={resetCaptcha} parentCallback={setCaptchaValue} />
								</Col>
							</Row>
							<Row className="justify-content-md-center">
								<Col xs lg="6" className="text-center">
									<div className="btn-div">
										<button type="button" onClick={formSubmit} className="btn btn-primary" id="submit-code-btn">
											<Trans>Submit Code</Trans>
										</button>
										<p align="center">
											<Link id="href-reset" to="#" onClick={resetComponent}>
												<Trans>Reset</Trans>
											</Link>
										</p>
									</div>
								</Col>
							</Row>
						</>
					) : (
						<>
							{codeStatusList.map((v, i) => {
								return (
									<Row className="justify-content-md-center">
										<Col xs lg="6" className={v.isValid ? "response-message-div text-center bg-success" : "response-message-div text-center bg-warning"}>
											<h5 className="text-center">{v.code}</h5>
											{v.isValid ? (
												<p className="validate-code-response-msg" id={`msg-${v.code}-${i}`}>
													<Trans>Congratulations</Trans>! <Trans>You have successfully validated your code</Trans>.
												</p>
											) : (
												<p className="validate-code-response-msg" id={`msg-${v.code}-${i}`}>
													<Trans>Sorry</Trans>! <Trans>This code is not valid</Trans>.
												</p>
											)}
										</Col>
									</Row>
								);
							})}
							<Row className="justify-content-md-center">
								<Col xs lg="6" className="text-center">
									<div className="btn-div">
										<button type="button" onClick={resetComponent} className="btn btn-primary" id="submit-more-code-btn">
											<Trans>ENTER MORE CODES</Trans>
										</button>
									</div>
								</Col>
							</Row>
						</>
					)}
				</Container>
				<Modal dialogClassName="modal-80w" show={showPopup} onHide={(e) => setShowPopup(false)} size="lg" aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
					</Modal.Header>
					<Modal.Body className="show-grid">
						<Container>
							<Row>
								<Col xs={12} md={6}>
									<p>
										<Trans>IF THIS IS YOUR INSERT</Trans>,{" "}
										<Link id="href-goback" to="#" className="validate-purchase-href-model-link" onClick={(e) => setShowPopup(false)}>
											<Trans>GO BACK</Trans>
										</Link>{" "}
										<Trans>AND ENTER YOUR CODE</Trans>!
									</p>
								</Col>
								<Col xs={6} md={6}>
									<p>
										<Trans>IF THIS IS YOUR INSERT</Trans>, <Trans>PLEASE VISIT</Trans>
										<a id="validate-purchase-href-go-others-link" className="validate-purchase-href-model-link" target="new" href="https://dev.userportal-terra.rsi-rnd.com/validate-code">
											{" "}
											DEV.USERPORTAL-TERRA.RSI-RND.COM{" "}
										</a>
										<Trans>TO REDEEM YOUR DIGITAL COPY</Trans>
									</p>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={6}>
									<img className="validate-purchase-div-modal-image" src={defaultOptions.imageBaseUrl + "/public/webstatic/images/vci.png"} />
								</Col>
								<Col xs={6} md={6}>
									<img className="validate-purchase-div-modal-image" src={defaultOptions.imageBaseUrl + "/public/webstatic/images/online-trolls.jpg"} />
								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>
			</div>
		</Container>
	);
};

export default IsLoadingHOC(SubmitCode, "Loading....");
