import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { toast } from "react-toastify";
import { uploadImageAction } from "../../redux/actions";
import PreviousReceiptList from "./PreviousReceiptList";
import FileDropZone from "../FileDropZone";
const UploadReceipt = (props) => {
	const { t } = useTranslation();
	let [selectedFile, setSelectedFile] = useState(null);
	const [receiptLoadCounter, setReceiptLoadCounter] = useState(1);
	const uploadImage = () => {
		if (!selectedFile) {
			toast.error(`${t("Error")}! ${t("Select a receipt")}`);
			return false;
		}
		const formData = new FormData();
		formData.append("type", "receipt");
		formData.append("image", selectedFile);
		props.setLoading(true);
		uploadImageAction(formData)
			.then((responseData) => {
				props.setLoading(false);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				if (responseData["httpCode"] === 200) {
					toast.success(`${t("Your receipt has been uploaded successfully. We will get back to you within 24-48 hours once we verify it")}.`);
					setSelectedFile(null);
					return true;
				} else if (responseData["httpCode"] === 409) {
					toast.error(`${t("Error")}! This receipt has already been uploaded. Please upload another receipt.`);
					setSelectedFile(null);
					return true;
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);
					return false;
				}
			})
			.catch((err) => {
				console.log("error", err);
				props.setLoading(false);
				if (typeof err === "object") {
					if (err["response"]) {
						if (err["response"].httpCode === 409) {
							//duplicate Receipt message
							toast.error(`${t("Error")}! This receipt has already been uploaded. Please upload another receipt.`);
							setSelectedFile(null);
							return false;
						} else {
							toast.error(`${t("Error")}! ${t("Something went wrong")}`);
							return false;
						}
					} else {
						toast.error(`${t("Error")}! ${t("Something went wrong")}`);
						return false;
					}
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}`);
					return false;
				}
			});
	};
	return (
		<Container fluid>
			<div className="validate-purchase-div">
				<Container fluid>
					<Row xs={1} md={1} lg={1}>
						<h2 className="section-heading heading text-center">
							<Trans>Upload your receipt</Trans>
						</h2>
					</Row>
					<Row xs={1} md={1} lg={1}>
						<h5 className="h2-disc1">
							<Trans>Take a clear picture of your entire receipt. The receipt must include the name and date of purchase on the photo.</Trans>
						</h5>
						<p align="center">
							<Trans>Click on the box below to upload your receipt. One image will be accepted in JPG, PNG, or PDF format per entry.</Trans>
						</p>
					</Row>
					<Row xs={1} md={1} lg={1}>
						<label className="label-disc">
							<Trans>Note</Trans>: <Trans>Size of the receipt must be less than</Trans> 5 MB.
						</label>
					</Row>
					<Row className="justify-content-md-center">
						<Col md="12">
							<PreviousReceiptList reloadCounter={receiptLoadCounter} />
						</Col>
					</Row>
					<Row>
						<FileDropZone parentCallBack={setSelectedFile} selectedFile={selectedFile} />
					</Row>

					<Row>
						<div className="btn-div">
							<Button size="lg" type="button" onClick={uploadImage} className="btn btn-primary" id="upload-btn">
								<Trans>Submit</Trans>
							</Button>
						</div>
					</Row>
				</Container>
			</div>
		</Container>
	);
};

export default IsLoadingHOC(UploadReceipt, "Loading....");
