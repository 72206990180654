import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { defaultOptions } from "../config/index";
const GooglePlaces = (props) => {
	const selectedPlaceHandler = (place) => {
		if (props.getPlaceHandler) {
			let placeDetails = { address: place.formatted_address, country: "", city: "", state: "", zip: "", street: "" };
			if (place.address_components) {
				let cuntryInd = place.address_components.findIndex((v) => {
					return v.types.indexOf("country") >= 0 ? true : false;
				});
				placeDetails.country = cuntryInd >= 0 ? place.address_components[cuntryInd].long_name : "";

				let stateInd = place.address_components.findIndex((v) => {
					return v.types.indexOf("administrative_area_level_1") >= 0 ? true : false;
				});
				placeDetails.state = stateInd >= 0 ? place.address_components[stateInd].long_name : "";

				let cityInd = place.address_components.findIndex((v) => {
					return v.types.indexOf("locality") >= 0 ? true : false;
				});
				placeDetails.city = cityInd >= 0 ? place.address_components[cityInd].long_name : "";

				let pincodeInd = place.address_components.findIndex((v) => {
					return v.types.indexOf("postal_code") >= 0 ? true : false;
				});
				placeDetails.zip = pincodeInd >= 0 ? place.address_components[pincodeInd].long_name : "";

				let streetInd = place.address_components.findIndex((v) => {
					return v.types.indexOf("street_number") >= 0 ? true : false;
				});
				placeDetails.street = streetInd >= 0 ? place.address_components[streetInd].long_name : "";
			}
			props.getPlaceHandler(placeDetails);
		}
	};
	const { ref } = usePlacesWidget({
		apiKey: defaultOptions.googleMapAPIKey,
		onPlaceSelected: (place) => selectedPlaceHandler(place),
		options: {
			types: defaultOptions.googleMapLoactionTypes,
		},
	});
	return <input type="text" placeholder={props.placeholder} ref={ref} className="form-control" />;
};
export default GooglePlaces;
