import React from "react";
import PortalPointList from "../../components/PortalPointList";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions";
import CarouselCustom from "../../components/CarouselCustom";
import Contests from "../../components/Contests";
import Support from "../../components/Support";
import ParticipateInstructions from "../../components/ParticipateInstructions";
class Home extends React.Component {
	render() {
		return (
			<>
				<CarouselCustom />
				<ParticipateInstructions />
				<FrequentlyAskedQuestions />
				<Support />
			</>
		);
	}
}

export default Home;
