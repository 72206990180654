import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { signup, getLockupAction, preRegistrationAction } from "../../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GooglePlaces from "../../components/GooglePlaces";
//import Calendar from "react-calendar";
import Required from "../../components/Required";
import Captcha from "../../components/CaptchaClick";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
var validator = require("validator");
const Register = ({ signup, setLoading }) => {
	const { t } = useTranslation();
	const confige = useSelector((state) => state.app.config);
	const history = useHistory();
	const [place, setPlace] = useState({});
	const query = new URLSearchParams(window.location.search);
	const referralid = query.get("refCode") ? query.get("refCode") : "";
	const source = query.get("src") ? query.get("src") : "";
	const promoCode = query.get("promoCode") ? query.get("promoCode") : "";
	let [captchaValue, setCaptchaValue] = useState(false);
	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({'firstName':'','lastName':'','email':'','username':'','cellPhone':'',})
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({ firstName: "", email: "", homePhone: "", cellPhone: "", lastName: "", username: "", address: "", country: "", state: "", suite: "", zip: "", city: "", shippingInstructions: "", receiveEmail: "", receiveSms: "", gender: "", passwordConfirm: "", password: "", instagramHandle: "", facebookHandle: "", tiktokHandle: "", twitterHandle: "" });
	//const [calender, setCalender] = useState(false);
	useEffect(() => {
		let items = {};
		let oldState = { ...data, password: "", passwordConfirm: "" };
		setData(oldState);
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
			});
			//console.log("password", confige.uiConfig.passwordRule);
			items.password = confige.uiConfig.passwordRule;
			items.passwordConfirm = confige.uiConfig.passwordRule;
			if (confige.fingerprinting) {
				if (confige.fingerprinting.enable) {
					const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
					// Get the visitor identifier when you need it.
					fpPromise
						.then((fp) => fp.get())
						.then((result) => {
							let fdata = JSON.parse(JSON.stringify(data));
							fdata.additionalInfo.visitorId = result.visitorId;
							fdata.additionalInfo.requestId = result.requestId;
							setData(fdata);
						});
				}
			}
		}
		if (!confige.useCaptcha) {
			setCaptchaValue("none");
		}
		//console.log("items", items);
		setValidation(items);
	}, [confige, confige.uiConfig]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		//console.log(name, validation);
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					errorMessage = t("Should not be less then", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = t("Should not be greater then", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = t("Should not be empty");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "hasNumeric" && !validationArray[key] && value.search(/[0-9]/) > 0) {
				errorMessage = t("Should not be contain number");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = t("Should be contain number");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
				errorMessage = t("Should be contain alphabets");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = t("Please enter a valid email");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key]) {
				if (value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					errorMessage = t("Should have atleast one special letter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}

			if (name === "passwordConfirm") {
				if (data.password !== value) {
					setError({ ...error, passwordConfirm: t("Password and confirm password should be same") });
				}
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	const onSubmit = async () => {
		let errorArray = {};
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			if (name === "birth" && isNaN(new Date(value)) && value.length !== 0) {
				errorMessage = `${t("Invalid")} ${t("Birth Date")}`;
			} else if (name === "birth") {
				errorMessage = "";
			}
			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = t("Please enter a valid email");
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = t("Should not be less then", { count: validationArray[key] });
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = t("Should not be greater then", { count: validationArray[key] });
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = t("Should not be empty");
				} else if (key === "upperCaseReq" && value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
				} else if (key === "lowerCaseReq" && value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
				} else if (key === "hasNumeric" && !validationArray[key] && value.search(/[0-9]/) > 0) {
					errorMessage = t("Should not be contain number");
				} else if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
					errorMessage = t("Should be contain number");
				} else if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
					errorMessage = t("Should be contain alphabets");
				} else if (key === "specialCharReq" && value.length !== 0) {
					var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
					if (!format.test(value)) {
						errorMessage = t("Should have atleast one special letter");
					}
				}
			}
			errorArray[name] = errorMessage;

			if (name === "password" || name === "passwordConfirm") {
				if (data["password"] !== data["passwordConfirm"]) {
					errorMessage = t("Password and confirm password should be same");
				}
				errorArray["passwordConfirm"] = errorMessage;
			}
		}
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!captchaValue) {
			toast.error(`${t("Error")}! ${t("Please select captcha")}`);
			return false;
		}
		const newData = { ...data };
		if (captchaValue !== "none") {
			newData["captchaVal"] = captchaValue;
		}
		delete newData.passwordConfirm;
		if (!check) {
			if (newData.birth) {
				let datevalue = new Date(newData.birth);
				const dd = String(datevalue.getDate()).padStart(2, "0");
				const mm = String(datevalue.getMonth() + 1).padStart(2, "0");
				const yyyy = datevalue.getFullYear();
				const formatedDate = yyyy + "-" + mm + "-" + dd;
				newData.birth = formatedDate;
			}
			if (!newData.hasOwnProperty("additionalInfo")) {
				newData["additionalInfo"] = {};
			}
			if (promoCode) {
				newData["additionalInfo"]["promoCode"] = promoCode;
			}
			if (source) {
				newData["additionalInfo"]["source"] = source;
			}
			setLoading(true);
			try {
				let signupCall = true;
				if (confige.usePreRegistration) {
					signupCall = false;
					let preRegistrationResp = await preRegistrationAction(newData);
					if (preRegistrationResp.data) {
						if (preRegistrationResp.data.valid) {
							signupCall = true;
							newData.additionalInfo.token = preRegistrationResp.data.token;
						} else {
							toast.error(preRegistrationResp.data.errorReason);
						}
					} else {
						toast.error(t("Something went wrong"));
					}
				}
				if (signupCall) {
					let signupResp = await signup(newData, referralid);
					toast.success(signupResp);
					history.push("/login");
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
				if (typeof error === "object") {
					error.map((item) => {
						toast.error(item.message);
					});
				} else {
					toast.error(t("Something went wrong"));
				}
			}
		}
	};
	const handleDate = (value) => {
		setData({ ...data, birth: value });
	};
	useEffect(() => {
		if (place.hasOwnProperty("address")) {
			let curStateData = Object.assign({}, data);
			if (curStateData.hasOwnProperty("address")) {
				curStateData.address = place.address;
			}
			if (curStateData.hasOwnProperty("country")) {
				curStateData.country = place.country;
			}
			if (curStateData.hasOwnProperty("state")) {
				curStateData.state = place.state;
			}
			if (curStateData.hasOwnProperty("city")) {
				curStateData.city = place.city;
			}
			if (curStateData.hasOwnProperty("suite")) {
				curStateData.suite = place.street;
			}
			if (curStateData.hasOwnProperty("zip")) {
				curStateData.zip = place.zip;
			}
			setData(curStateData);
		}
	}, [place]);
	const googleSelectedAddressHandler = (place) => {
		setPlace(place);
	};
	return (
		<div className="register-page">
			<Card>
				<form>
					<h1 className="main-heading">{t("Register Now")}</h1>
					{/*[START:FIRSTNAME]*/}
					<div className="form-group row theme-signup-firstname">
						<label htmlFor="first-name" className="col-sm-2 col-form-label">
							<Trans>lang_key_fname</Trans>
							{validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""}
						</label>
						<div className="col-sm-10">
							<input type="text" data-test="register-firstName" name="firstName" onChange={handleChange} className="form-control" id="first-name" placeholder={t("lang_key_fname")} />
							<h5 className="input-error">{error.firstName}</h5>
						</div>
					</div>
					{/*[END:FIRSTNAME]*/}

					{/*[START:LASTNAME]*/}
					<div className="form-group row theme-signup-lastname">
						<label htmlFor="last-name" className="col-sm-2 col-form-label">
							<Trans>lang_key_lname</Trans>
							{validation["lastName"] && validation["lastName"].mandatory ? <Required /> : ""}
						</label>
						<div className="col-sm-10">
							<input type="text" data-test="register-lasttName" name="lastName" onChange={handleChange} className="form-control" id="last-name" placeholder={t("lang_key_lname")} />
							<h5 className="input-error">{error.lastName}</h5>
						</div>
					</div>
					{/*[END:LASTNAME]*/}

					{/*[START:USERNAME]*/}
					<div className="form-group row theme-signup-username">
						<label htmlFor="user-name" className="col-sm-2 col-form-label">
							<Trans>lang_key_username</Trans>
							{validation["username"] && validation["username"].mandatory ? <Required /> : ""}
						</label>
						<div className="col-sm-10">
							<input type="text" data-test="register-username" name="username" onChange={handleChange} className="form-control" id="user-name" placeholder={t("lang_key_username")} />
							<h5 className="input-error">{error.username}</h5>
						</div>
					</div>
					{/*[END:USERNAME]*/}

					{/*[START:EMAIL]*/}
					<div className="form-group row theme-signup-email">
						<label htmlFor="email" className="col-sm-2 col-form-label">
							<Trans>lang_key_email</Trans>
							{validation["email"] && validation["email"].mandatory ? <Required /> : ""}
						</label>
						<div className="col-sm-10">
							<input type="email" data-test="register-email" name="email" onChange={handleChange} className="form-control" id="email" placeholder={t("lang_key_email")} />
							<h5 className="input-error">{error.email}</h5>
						</div>
					</div>
					{/*[END:EMAIL]*/}
					
					
					
					
					
					
					
					
					
					
					
					
					
					
					

					
					
					

					
					
					

					
					
					

					
					
					

					
					
					

					
					
					

					
					
					

					
					
					

					
					
					

					{/*[START:CELLPHONE]*/}
					<div className="form-group row theme-signup-homePhone">
						<label htmlFor="cellPhone" className="col-sm-2 col-form-label">
							<Trans>Cell Phone</Trans>
							{validation["cellPhone"] && validation["cellPhone"].mandatory ? <Required /> : ""}
						</label>
						<div className="col-sm-10">
							<input type="number" data-test="signup-cellPhone" name="cellPhone" onChange={handleChange} className="form-control" id="cellPhone" placeholder={t("Cell Phone")} />
							<h5 className="input-error">{error.cellPhone}</h5>
						</div>
					</div>
					{/*[END:CELLPHONE]*/}

					
					
					

					
					
					

					
					
					
					
					
					
					
					
					
					<div className="row submit">
						<button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn">
							<Trans>Create Account</Trans>
						</button>
					</div>
					<div className="forgot-pass-links text-center text-lg-left">
						<a href="/login" id="login-href">
							<Trans>click here to login your account.</Trans>
						</a>
					</div>
				</form>
			</Card>
		</div>
	);
};

export default connect(null, { signup })(IsLoadingHOC(Register, "Wait ....."));
