import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import IsLoadingHOC from "./IsLoadingHOC";
import { helpQueryAction } from "../redux/actions";
import { toast } from "react-toastify";
import Captcha from "./CaptchaClick";
const Support = (props) => {
	const { t } = useTranslation();
	const [data, setData] = useState({
		name: "",
		email: "",
		description: "",
		captchaVal: "",
	});
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	let [showAlert, setShowAlert] = useState(false);
	const [error, setError] = useState({ name: "", email: "", description: "", captchaVal: "" });
	const validationError = { description: "", firstName: /^[A-Za-z]+$/, email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, lastName: /^[A-Za-z]+$/, captchaVal: "" };
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captchaVal"] = "";
			let reqd = JSON.parse(JSON.stringify(data));
			reqd.captchaVal = captchaValue;
			setData(reqd);
		}
		setError(errorArray);
	}, [captchaValue]);
	const handleChange = (e) => {
		let key = e.target.name;
		let val = e.target.value;
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));
		if (val.match(validationError[key]) || val === "" || key === "email") {
			reqd[key] = val;
		}
		if (key === "name") {
			if (!val || val.trim().length < 1) {
				errd[key] = `Name is required`;
			} else {
				errd[key] = ``;
			}
		} else if (key === "email") {
			if (!val.match(validationError[key])) {
				errd[key] = `Email is required`;
			} else {
				errd[key] = ``;
			}
		} else if (key === "description") {
			if (!val || val.trim().length < 1) {
				errd[key] = `Message is required`;
			} else {
				errd[key] = ``;
			}
		}
		setError(errd);
		setData(reqd);
	};
	const onSubmit = () => {
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));
		let isError = false;
		for (let key in reqd) {
			let val = reqd[key].trim();
			if (key === "name") {
				if (!val || val.length < 1) {
					isError = true;
					errd[key] = `Name is required`;
				} else {
					errd[key] = ``;
				}
			} else if (key === "email") {
				if (!val.match(validationError[key])) {
					errd[key] = `Email is required`;
					isError = true;
				} else {
					errd[key] = ``;
				}
			} else if (key === "description") {
				if (!val || val.length < 1) {
					errd[key] = `Message is required`;
					isError = true;
				} else {
					errd[key] = ``;
				}
			} else if (key === "captchaVal") {
				if (!val || val.length < 2) {
					errd[key] = `Please check Captcha`;
					isError = true;
				} else {
					errd[key] = ``;
				}
			}
		}
		setError(errd);
		if (!isError) {
			props.setLoading(true);
			let requestData = { email: reqd.email, name: reqd.name, message: reqd.description, captchaVal: reqd.captchaVal };
			helpQueryAction(requestData)
				.then((response) => {
					for (let key in reqd) {
						reqd[key] = "";
					}
					setResetCaptchaValue(resetCaptchaValue + 1);
					setData(reqd);
					setShowAlert(true);
					props.setLoading(false);
				})
				.catch((error) => {
					toast.error(t("Something went wrong"));
					props.setLoading(false);
				});
		}
	};
	return (
		<Container className="justify-content-md-center section-content" id="support-content">
			<Form>
				<h1 className="main-heading section-heading text-center">{t("Support")}</h1>
				<p className="text-center">If you have any inquiries or trouble registering, please message us below. Please note - this is not a chatbox.</p>
				{showAlert ? (
					<Row md={1}>
						<Col className="text-center">
							<Alert variant="success">
								<Alert.Heading>Thank you for query. </Alert.Heading>
								<p>We will get back to you soon. Have a great day!</p>
							</Alert>
						</Col>
					</Row>
				) : (
					""
				)}
				<Row md={2}>
					{/*[START:FIRSTNAME]*/}
					<Col className="form-group theme-signup-firstname">
						<Form.Control size="lg" type="text" value={data.name} data-test="register-name" name="name" onChange={handleChange} onFocus={handleChange} id="Name" placeholder={t("Name")} />
						<h5 className="input-error">{error.name}</h5>
					</Col>
					{/*[END:FIRSTNAME]*/}

					{/*[START:EMAIL]*/}
					<Col className="form-group theme-signup-email">
						<Form.Control size="lg" type="email" value={data.email} data-test="register-email" name="email" onChange={handleChange} onFocus={handleChange} id="email" placeholder={t("lang_key_email")} />
						<h5 className="input-error">{error.email}</h5>
					</Col>
					{/*[END:EMAIL]*/}
				</Row>
				<Row md={1}>
					<Col className="form-group theme-signup-email">
						<Form.Control size="lg" as="textarea" value={data.description} placeholder={t("Leave a description here")} onChange={handleChange} name="description" style={{ height: "100px" }} onFocus={handleChange} />
						<h5 className="input-error">{error.description}</h5>
					</Col>
				</Row>
				<Row md={4}>
					<Col>
						<Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
						<h5 className="input-error text-center">{error.captchaVal}</h5>
					</Col>
				</Row>
				<Row md={1}>
					<Col className="text-center">
						<Button variant="primary" className="btn btn-primary themeBackground" size="lg" id="register-submit-btn" data-test="register-submit" onClick={onSubmit}>
							<Trans>Submit</Trans>
						</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
};

export default IsLoadingHOC(Support, "Wait .....");
