import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import TopRight from "./TopRight";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Menu = ({ data }) => {
	const { t } = useTranslation();
	const user = useSelector(({ user }) => user);
	return (
		<Fragment>
			<button id="button-navbar-toggler" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span id="span-navbar-toggler-icon" className="navbar-toggler-icon"></span>
			</button>

			<div className="collapse navbar-collapse top-navigation" id="navbarSupportedContent">
				<ul className="navbar-nav">
					{/*[START:HOME]*/}
					<Link className="nav-link nav-link-custom" id="href-home" to="/">
						<li className="nav-item nav-item-custom">{t("Home")}</li>
					</Link>
					{/*[END:HOME]*/}

					{/*[START:HOWTOPARTICIPATE]*/}
					<Link className="nav-link nav-link-custom" id="href-htp" to="/htp">
						<li className="nav-item nav-item-custom">{t("How to participate")}</li>
					</Link>
					{/*[END:HOWTOPARTICIPATE]*/}

					{/*[START:REGISTER]*/}
					<Link className="nav-link nav-link-custom" id="href-register" to="/register">
						<li className="nav-item nav-item-custom">{t("Register")}</li>
					</Link>
					{/*[END:REGISTER]*/}

					{/*[START:RECEIPTUPLOAD]*/}
					<Link className="nav-link nav-link-custom" id="href-receipt-upload" to="/upload-receipt">
						<li className="nav-item nav-item-custom">{t("Receipt Upload")}</li>
					</Link>
					{/*[END:RECEIPTUPLOAD]*/}

					{/*[START:FAQ]*/}
					<Link className="nav-link nav-link-custom" id="href-faq" to="/faq">
						<li className="nav-item nav-item-custom">{t("Faq")}</li>
					</Link>
					{/*[END:FAQ]*/}

					{/*[START:SUPPORT]*/}
					<Link className="nav-link nav-link-custom" id="href-support" to="/support">
						<li className="nav-item nav-item-custom">{t("Support")}</li>
					</Link>
					{/*[END:SUPPORT]*/}

					{/* My All-Access , Points History, My Rewards, Profile, Connect Movie ANYHERE, Logout */}
					<TopRight />
				</ul>
			</div>
		</Fragment>
	);
};

export default Menu;
