import React from "react";
import { Row, Col } from "react-bootstrap";
class ParticipateInstructions extends React.Component {
	render() {
		return (
			<Row className="justify-content-md-center section-content" id="participate-content">
				<Col md={12}>
					<h1 className="section-heading text-center">How To Participate</h1>
				</Col>
				<Col md={10}>
					<p className="text-center">
						<p>You must register and upload your receipt to be eligible for a reward. Please follow the instructions below.</p>
					</p>
					<h4 className="text-center">
						<strong>1. BUY</strong>
					</h4>
					<p className="text-center">Purchase any one Mucinex Fast-Max or Nightshift Cold & Flu product between January 15 and March 27, 2022. See full list of participating products below.</p>
					<h4 className="text-center">
						<strong>2. REGISTER</strong>
					</h4>
					<p className="text-center">Register with your name and email address by April 1, 2022.</p>
					<h4 className="text-center">
						<strong>3. TAKE A PICTURE</strong>
					</h4>
					<p className="text-center">Snap a photo of your receipt showing your qualifying purchase. Don’t forget that the photo of each receipt must display the date and time of the transaction and the qualifying products purchased. If the receipt is too long, please fold the receipt so that the date and time and qualifying purchases are included in the picture.</p>
					<h4 className="text-center">
						<strong>4. UPLOAD</strong>
					</h4>
					<p className="text-center">
						Once you complete registration, you will be prompt to upload your receipt. Look out for an email confirming your receipt was uploaded and received. Receipts can take up to one to two business days to process and will be subject to verification before an approval will be received, so please be patient. All eligible receipts MUST be uploaded by April 1, 2022 at 11:59:59 PM
						(EST).
					</p>
					<h4 className="text-center">
						<strong>5. SPIN TO WIN</strong>
					</h4>
					<p className="text-center">Once your receipt is approved, you will be eligible to play on our spin wheel to win a prize! All eligible participants must play “Spin to Win” game by April 8, 2022 at 11:59PM (EST).</p>
				</Col>
			</Row>
		);
	}
}

export default ParticipateInstructions;
