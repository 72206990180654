import React, { useState } from "react";
import { Row, Col, Accordion, Card, Button, Nav } from "react-bootstrap";
const FrequentlyAskedQuestions = () => {
	const [data, setData] = useState([
		{ id: 0, clicked: false, name: "How long do I have to submit my receipt?", content: "All products must be purchased between November 29, 2021 and January 2, 2022. You have until January 2, 2022 at 11:59PM EST to upload your receipt." },
		{ id: 1, clicked: false, name: "What do I do if I am having trouble participating in the program?", content: "You can submit any questions you may have about the promotion to our support box." },
		{ id: 2, clicked: false, name: "What are the participating products?", content: "Click here to see the full list of participating products." },
		{ id: 3, clicked: false, name: "How many times can I submit the same valid receipt?", content: "You can only submit a valid receipt once, receipts submitted more than once will be rejected." },
		{ id: 4, clicked: false, name: "How many rewards can I get?", content: "There is a limit of one reward per email address." },
		{ id: 5, clicked: false, name: "Do I have to purchase all $15 of participating products in one transaction?", content: "Yes, you need to purchase $15 of participating products in a single transaction." },
		{ id: 6, clicked: false, name: "What do I do if I have additional questions?", content: "Review the Terms and Conditions for the offer for additional details. If the Terms and Conditions have not answered your question, please email us via the support box." },
	]);
	const [faqStatus, setFaqStatus] = useState(false);
	const toggleHandler = (i) => {
		let tempData = JSON.parse(JSON.stringify(data));
		tempData[i].clicked = tempData[i].clicked ? false : true;
		setData(tempData);
	};
	const bulkActionHandler = () => {
		let status = !faqStatus;
		setFaqStatus(status);
		let tempData = data.map((v) => ({ ...v, clicked: status }));
		setData(tempData);
	};
	return (
		<Row className="justify-content-md-center section-content" id="faq-content">
			<Col md={12}>
				<h1 className="section-heading text-center">Frequently Asked Questions</h1>
			</Col>
			<Col md={11} className="text-right">
				<Button onClick={bulkActionHandler} variant="link">
					{!faqStatus ? "Open All" : "Close All"}
				</Button>
			</Col>
			<Col md={11}>
				<Accordion>
					{data.map((v, i) => {
						return (
							<Card style={{ marginBottom: "0px" }}>
								<Card.Header style={{ backgroundColor: "rgb(238, 235, 235)", color: "#000", fontWeight: "bold" }}>
									<Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" onClick={(e) => toggleHandler(i)} eventKey={v.id}>
										<Row style={{ cursor: "pointer" }}>
											<Col md={11}>{v.name}</Col>
											<Col md={1} className="text-right" style={{ color: "#000", lineHeight: "32px", fontSize: "32px", fontWeight: "bold" }}>
												{v.clicked ? "-" : "+"}
											</Col>
										</Row>
									</Accordion.Toggle>
								</Card.Header>
								{v.clicked ? (
									<Accordion eventKey={v.id}>
										<Card.Body>{v.content}</Card.Body>
									</Accordion>
								) : (
									<Accordion.Collapse eventKey={v.id}>
										<Card.Body>{v.content}</Card.Body>
									</Accordion.Collapse>
								)}
							</Card>
						);
					})}
				</Accordion>
			</Col>
		</Row>
	);
};

export default FrequentlyAskedQuestions;
