import React, { useState } from "react";
import { logoutAction } from "../redux/actions";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { Modal, Button, CloseButton } from "react-bootstrap";
import IsLoadingHOC from "./IsLoadingHOC";

const Logout = (props) => {
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
	};
	const handleLogout = () => {
		props.setLoading(true);
		setShow(false);
		props
			.logoutAction()
			.then((response) => {
				props.setLoading(false);
				window.location.href = "/";
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
				window.location.href = "/";
			});
	};
	return (
		<>
			<button id="href-logout" type="button" id="button-logout" onClick={handleShow} className="btn btn-secondary theme-logout-button-top-right">
				<Trans>Logout</Trans>
			</button>
			<Modal show={show}>
				<Modal.Header>
					<Modal.Title>
						<Trans>Confirm Logout</Trans>
					</Modal.Title>
					<CloseButton onClick={handleClose} />
				</Modal.Header>
				<Modal.Body>
					<Trans>Are you sure you want to logout now</Trans>?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						<Trans>Close</Trans>
					</Button>
					<Button variant="primary" onClick={handleLogout}>
						<Trans>Logout</Trans>
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
export default connect(null, { logoutAction })(IsLoadingHOC(Logout, "Loading...."));
