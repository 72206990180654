import React, { useEffect, useState } from "react";
import { ticketsAction } from "../../redux/actions";
import { Button, Container, Col, Row, Table } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Trans, useTranslation } from "react-i18next";
import NewTicket from "./NewTicket";
import { Link } from "react-router-dom";

const TicketList = (props) => {
	const { t } = useTranslation();
	const pageLimit = 8;
	const [pageNo, setPageNo] = useState(0);
	const [viewPaginationBtn, setViewPaginationBtn] = useState(false);
	const [createTicket, setCreateTicket] = useState(false);
	const [data, setData] = useState([]);
	const [ticketSelectBox, setTicketSelectBox] = useState("");
	let [ticketListData, setTicketListData] = useState([]);

	const statusList = [
		{ key: "active", name: "Active" },
		{ key: "on-hold", name: "On Hold" },
		{ key: "waiting", name: "Waiting on customer" },
		{ key: "solved", name: "Solved" },
		{ key: "closed", name: "Closed" },
	];

	useEffect(() => {
		getTicketList();
	}, []);
	const getTicketList = () => {
		props.setLoading(true);
		setPageNo(0);
		ticketsAction()
			.then((response) => {
				if (response.data) {
					let ticket = response.data.content;
					setTicketListData(ticket);
				}
				props.setLoading(false);
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
			});
	};
	const getNextPageData = () => {
		let tempTicketList = JSON.parse(JSON.stringify(ticketListData));
		if (ticketSelectBox !== "") {
			tempTicketList = tempTicketList.filter((v) => v.status === ticketSelectBox);
		}
		let pageData = tempTicketList.slice(0, pageNo * pageLimit + pageLimit);
		setData(pageData);
		if (pageNo * pageLimit + pageLimit >= tempTicketList.length) {
			setViewPaginationBtn(false);
		} else {
			setViewPaginationBtn(true);
		}
	};
	const filterRewardList = async (e) => {
		setPageNo(0);
		setTicketSelectBox(e.target.value);
	};
	useEffect(() => {
		getNextPageData();
	}, [ticketListData, ticketSelectBox, pageNo]);
	const newTicketBtnClicked = (reloadTicket, newTicketData = {}) => {
		if (reloadTicket) {
			if (newTicketData) {
				let temparr = {
					id: newTicketData.id ? newTicketData.id : "",
					assignedTo: newTicketData.assignedTo ? newTicketData.assignedTo : {},
					preview: newTicketData.preview ? newTicketData.preview : "",
					priority: newTicketData.priority ? newTicketData.priority : "",
					status: newTicketData.state ? newTicketData.state : "active",
					subject: newTicketData.subject ? newTicketData.subject : new Date().toLocaleString(),
					isRead: newTicketData.isRead ? newTicketData.isRead : false,
					numThreads: 1,
					createdAt: newTicketData.createdAt ? newTicketData.createdAt : new Date().toLocaleString(),
					updatedAt: newTicketData.updatedAt ? newTicketData.updatedAt : new Date().toLocaleString(),
				};
				let tempTicketList = JSON.parse(JSON.stringify(ticketListData));
				tempTicketList.unshift(temparr);
				setTicketListData(tempTicketList);
				setPageNo(0);
			}
		}
		setCreateTicket(false);
	};
	if (createTicket) {
		return <NewTicket parentCallback={newTicketBtnClicked} />;
	} else {
		return (
			<Container className="help-desk-container">
				<Container fluid>
					<Container fluid>
						<hr />
						<Row>
							<Col xs={4} md={4}>
								<h2 className="heading2">
									<Trans>All</Trans> <Trans>Support</Trans> <Trans>Tickets</Trans>
								</h2>
							</Col>
							<Col xs={6} md={6}>
								<Row>
									<Col md={4}>
										<Trans>Ticket</Trans> <Trans>Status</Trans>
									</Col>
									<Col md={6}>
										<select className="form-control" id="ticketStatus" value={ticketSelectBox} onChange={filterRewardList}>
											<option value="">{`${t("Show")} ${t("All")}`}</option>
											{statusList.map((v, i) => {
												return (
													<option key={i + "_typeOption"} value={v.key}>
														{t(v.name)}
													</option>
												);
											})}
										</select>
									</Col>
									<Col md={2}>
										{" "}
										<Button title={`${t("Reload")} ${t("Tickets")} `} variant="secondary" onClick={(e) => getTicketList()} id="reload-ticket-btn" className="cart-button btn btn-success">
											&#8634;
										</Button>
									</Col>
								</Row>
							</Col>
							<Col xs={2} md={2} className="text-right">
								<Button variant="secondary" onClick={(e) => setCreateTicket(true)} disabled={createTicket ? true : false} id="new-ticket-btn" className="cart-button btn btn-warning">
									<Trans>New</Trans> <Trans>Ticket</Trans>
								</Button>
							</Col>
						</Row>
						<Table className="table table-striped" responsive="sm" responsive="lg" responsive="md" responsive="xs">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<Trans>Ticket</Trans>
									</th>
									<th>
										<Trans>Subject</Trans>
									</th>
									<th>
										<Trans>Created</Trans>
									</th>
									<th>
										<Trans>Last Updated</Trans>
									</th>
									<th>
										<Trans>Status</Trans>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => {
									return (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>
												<Link to={`/help-desk/${item.id}`} id={`ticket-btn-${item.id}`}>
													{item.id}
												</Link>
											</td>
											<td>{item.subject}</td>
											<td>{new Date(item.createdAt).toLocaleString()}</td>
											<td> {new Date(item.updatedAt).toLocaleString()}</td>
											<td style={{ textTransform: "capitalize" }}> {item.status}</td>
											<td>
												<Link to={`/help-desk/${item.id}`} id={`ticket-btn-${item.id}`} className="cart-button btn btn-primary">
													<Trans>Details</Trans>
												</Link>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
						<hr />
						<div className="text-center" style={{ width: "100%" }}>
							{viewPaginationBtn ? (
								<Button variant="primary" id="view-more-help-desk-ticket-btn" className="viewMore" onClick={() => setPageNo(pageNo + 1)}>
									<Trans>View More</Trans> <Trans>Tickets</Trans>
								</Button>
							) : (
								<Button variant="secondary" disabled id="view-more-help-desk-ticket-btn" className="viewMore">
									<Trans>All</Trans> <Trans>Tickets</Trans> <Trans>Shown</Trans>
								</Button>
							)}
						</div>
					</Container>
				</Container>
			</Container>
		);
	}
};

export default IsLoadingHOC(TicketList, "Loading....");
