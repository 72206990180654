import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";

const { SearchBar } = Search;
const TableReport = (props) => {
	const { t } = useTranslation();
	const searchTextboxEnable = props.searchTextboxEnable;
	const options = {
		paginationSize: 4,
		pageStartIndex: 1,
		alwaysShowAllBtns: true, // Always show next and previous button
		//withFirstAndLast: false, // Hide the going to First and Last page button
		//hideSizePerPage: true, // Hide the sizePerPage dropdown always
		//hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		// firstPageText: "First",
		// prePageText: "Prev",
		// nextPageText: "Next",
		// lastPageText: "Last",
		nextPageTitle: `${t("Next")} ${t("page")}`,
		prePageTitle: `${t("Prev")} ${t("page")}`,
		firstPageTitle: `${t("First")} ${t("page")}`,
		lastPageTitle: `${t("Last")} ${t("page")}`,
		showTotal: true,
		disablePageTitle: false,
		sizePerPageList: [
			{
				text: "10",
				value: 10,
			},
			{
				text: "20",
				value: 20,
			},
			{
				text: "40",
				value: 40,
			},
		],
	};

	return (
		<ToolkitProvider keyField="id" data={props.data} columns={props.columns} keyField={props.keyField} search>
			{(props) => (
				<div className="table-report-div">
					{searchTextboxEnable ? <SearchBar style={{ width: "100%" }} {...props.searchProps} placeholder={t("Search Product")} /> : ""}
					<PaginationProvider pagination={paginationFactory(options)}>
						{({ paginationProps, paginationTableProps }) => (
							<div>
								<BootstrapTable {...props.baseProps} {...paginationTableProps} />
							</div>
						)}
					</PaginationProvider>
				</div>
			)}
		</ToolkitProvider>
	);
};
export default React.memo(TableReport);
