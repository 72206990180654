import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IsLoadingHOC from "./../components/IsLoadingHOC";
import { pointHistoryGet } from "../redux/actions";
import { Container, Table } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import "../assets/css/pagination.scss";

const PointHistory = (props) => {
	const { t } = useTranslation();
	const setLoading = props.setLoading;
	const [offset, setOffset] = useState(0);
	const [data, setData] = useState([]);
	const [perPage] = useState(10);
	const [pageCount, setPageCount] = useState(0);
	const [totalPoint, setTotalPoint] = useState(0);
	const getData = async () => {
		props
			.pointHistoryGet()
			.then((response) => {
				if (response["data"]) {
					const resData = response.data.content;
					let totp = 0;
					resData.map((val) => {
						if (val.ledger === "credit") {
							totp += val.points;
						} else {
							totp -= val.points;
						}
					});
					setTotalPoint(totp);
					setPageCount(Math.ceil(resData.length / perPage));

					const slice = resData.slice(offset, offset + perPage);
					const postData = slice.map((item, index) => {
						let itemDate = new Date(item.date);
						let newDate = itemDate.toLocaleDateString();
						return { description: item.description, newDate: newDate, ledger: item.ledger == "credit" ? "+" : "-", points: item.points };
					});
					setData(postData);
				}
				setLoading(false);
			})
			.catch((ex) => {
				console.log("error in Point List Api", ex);
				setLoading(false);
			});
	};

	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		setOffset(selectedPage * perPage);
	};

	useEffect(() => {
		setLoading(true);
		getData();
	}, [offset]);

	return (
		<Container fluid>
			<div className="container portal-history">
				<div className="point-history-label">
					<h4>
						{" "}
						<Trans>Point History</Trans>{" "}
					</h4>
					<span>
						<b>
							<Trans>Total Point</Trans>
						</b>{" "}
						{totalPoint}
					</span>
				</div>
				<Table>
					<thead>
						<tr>
							<th>
								<Trans>Description</Trans>
							</th>
							<th>
								<Trans>Date</Trans>
							</th>
							<th>
								<Trans>Points</Trans>
							</th>
						</tr>
					</thead>
					<tbody>
						{data.length > 0 ? (
							<>
								{data.map((row, i) => {
									return (
										<tr key={i}>
											<td>{row.description}</td>
											<td>{row.newDate}</td>
											<td>
												{row.ledger}
												{row.points}
											</td>
										</tr>
									);
								})}
							</>
						) : (
							<tr>
								<td colSpan="3">
									<center>
										<Trans>No point history found</Trans> !
									</center>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<ReactPaginate previousLabel={t("Prev")} nextLabel={t("Next")} breakLabel={"..."} breakClassName={"break-me"} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={handlePageClick} containerClassName={"pagination"} subContainerClassName={"pages pagination"} activeClassName={"active"} />
			</div>
		</Container>
	);
};

export default connect(null, { pointHistoryGet })(IsLoadingHOC(PointHistory, "Loading...."));
