import React from "react";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import MicrosoftLogin from "react-microsoft-login";
import { connect } from "react-redux";
import { facebookLogin, googleLoign, microsoftLogin } from "../redux/actions";
import IsLoadingHOC from "./IsLoadingHOC";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { Container, Modal } from "react-bootstrap";
import OAuthRegistration from "./OAuthRegistration";

class SocialLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            name: "",
            accessToken: "",
            email: "",
            roles: [],
            app: "",
            showModel: false,
            userData: {},
            updateId: "",
            msalInstance: "",
            error: "",
        };
    }
    responseFacebook = (resp) => {
        const { t } = this.props;
        const requestOptions = {
            id: resp.id,
            email: resp.email,
            name: resp.name,
            accessToken: resp.accessToken,
        };
        this.props.setLoading(true);
        this.props
            .facebookLogin(requestOptions)
            .then((response) => {
                if (response.httpCode === 202) {
                    let oldStat = {
                        ...this.state,
                        showModel: true,
                        userData: response.data,
                        updateId: "facebook",
                    };
                    this.setState(oldStat);
                    this.props.setLoading(false);
                } else {
                    this.props.setLoading(false);
                    this.props.history.push("/");
                    toast.success(t("User login successfully"));
                }
            })
            .catch((error) => {
                console.log("err", error);
                this.props.setLoading(false);
            });
    };
    responseGoogle = (resp) => {
        const { t } = this.props;
        const requestOptions = {
            id: resp.googleId,
            email: resp.profileObj ? resp.profileObj.email : "",
            name: resp.profileObj ? resp.profileObj.name : "",
            accessToken: resp.accessToken,
        };
        this.props.setLoading(true);
        this.props
            .googleLoign(requestOptions)
            .then((response) => {
                if (response.httpCode === 202) {
                    let oldStat = {
                        ...this.state,
                        showModel: true,
                        userData: response.data,
                        updateId: "google",
                    };
                    this.setState(oldStat);
                    this.props.setLoading(false);
                } else {
                    this.props.setLoading(false);
                    this.props.history.push("/");
                    toast.success(t("User login successfully"));
                }
            })
            .catch((error) => {
                console.log("err", error);
                this.props.setLoading(false);
            });
    };
    responseMicrosoft = (err, resp, msal) => {
        const { t } = this.props;
        try {
            if (!err && resp) {
                this.setState({ msalInstance: msal });
            }

            const requestOptions = {
                id: resp.idToken.objectId,
                email: resp.account.userName ? resp.account.userName : "",
                name: resp.account.name ? resp.account.name : "",
                accessToken: resp.accessToken,
            };
            this.props.setLoading(true);
            this.props
                .microsoftLogin(requestOptions)
                .then((response) => {
                    if (response.httpCode === 202) {
                        let oldStat = {
                            ...this.state,
                            showModel: true,
                            userData: response.data,
                            updateId: "microsoft",
                        };
                        this.setState(oldStat);
                        this.props.setLoading(false);
                    } else {
                        this.props.setLoading(false);
                        this.props.history.push("/");
                        toast.success(t("User login successfully"));
                    }
                })
                .catch((error) => {
                    console.log("err", error);
                    this.props.setLoading(false);
                });
        } catch (error) {
            console.log("err", error);
            this.props.setLoading(false);
        }
    };
    render() {
        const { error } = this.state;
        const { t } = this.props;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else {
            return (
                <Container>
                    <section className="social-login">
                    <div className="facebook-login">
                                    <FacebookLogin
                                        textButton={t("Login with Facebook")}
                                        appId="197499015615715"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        scope="public_profile,email"
                                        callback={this.responseFacebook}
                                    />
                                </div>
                                <div className="google-login">
                                    <GoogleLogin
                                        buttonText={t("login")}
                                        clientId="652043195533-uhd0dohl1l75uomaaoq76atbd6lfck5g.apps.googleusercontent.com"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={"single_host_origin"}
                                    />
                                </div>
                                <div className="microsoft-login">
                                    {!this.state.msalInstance ? (
                                        <MicrosoftLogin
                                            buttonText={t("login")}
                                            clientId="788e2f84-1580-4a13-a421-190f54279eaa"
                                            authCallback={
                                                this.responseMicrosoft
                                            }
                                            cookiePolicy={"single_host_origin"}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                        <Modal
                            size="xl"
                            show={this.state.showModel}
                            animation={false}
                        >
                            <Modal.Body>
                                <OAuthRegistration
                                    userData={this.state.userData}
                                    updateId={this.state.updateId}
                                />
                            </Modal.Body>
                        </Modal>
                    </section>
                </Container>
            );
        }
    }
}

export default connect(null, { facebookLogin, googleLoign, microsoftLogin })(
    IsLoadingHOC(withRouter(withTranslation()(SocialLogin)))
);
