import React, { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect, useSelector } from "react-redux";
import errorHtml from "../../components/Error";
import { login } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import SocialLogin from "../../components/SocialLogin";
import { Trans, useTranslation } from "react-i18next";
import SecondFactorAuth from "../../components/SecondFactorAuth";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
const Login = ({ setLoading, login }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const confige = useSelector((state) => state.app.config);
	const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [showSecondFactorPopup, setShowSecondFactorPopup] = useState(false);
	const [secondFactorAuthData, setSecondFactorAuthData] = useState({});
	const [errors, setErrors] = useState({ username: "", password: "" });
	const [fpData, setFpData] = useState({ visitorId: "", requestId: "" });
	const onChangeHandle = (e) => {
		const { name, value } = e.target;
		if (name === "username") {
			setUserName(e.target.value);
			value.length < 1 ? setErrors({ ...errors, username: t("User Name should not be empty") }) : setErrors({ ...errors, username: "" });
		}
	};
	const [redirectUrl, setRedirectUrl] = useState("/");
	React.useEffect(() => {
		if (confige.fingerprinting) {
			if (confige.fingerprinting.enable) {
				const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
				// Get the visitor identifier when you need it.
				fpPromise
					.then((fp) => fp.get())
					.then((result) => {
						setFpData({ visitorId: result.visitorId, requestId: result.requestId });
					});
			}
		}
	}, [confige]);
	React.useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		const redtUrl = query.get("route") ? query.get("route") : "/";
		if (redirectUrl !== redtUrl) {
			setRedirectUrl(redtUrl);
		}
	}, [window.location]);
	const handleError = () => {
		let value = false;
		const error = {};
		if (username.length < 1) {
			error.username = t("User Name should not be empty");
			value = true;
		}
		return { value, error };
	};

	const onSubmit = () => {
		const { value, error } = handleError();
		if (value) {
			setErrors({ ...errors, ...error });
			return false;
		}
		setLoading(true);
		login({ username, password, visitorId: fpData.visitorId, requestId: fpData.requestId })
			.then((response) => {
				setLoading(false);
				if (response.httpCode === 204) {
					setSecondFactorAuthData({ id: response.data });
					setShowSecondFactorPopup(true);
				} else {
					toast.success(t("User login successfully"));
					history.push(redirectUrl);
				}
			})
			.catch((error) => {
				console.log("Error in login", error);
				if (error["httpCode"] === 403) {
					toast.error(`${t("Your account is suspended. Please contact helpdesk for more information")}.`);
				} else if (error["httpCode"] === 406) {
					toast.error(`${t("Error")}! ${t("User not verified")}`);
				} else {
					toast.error(t("Invalid credentials"));
				}
				setLoading(false);
			});
	};

	return (
		<div className="register-page">
			<Card>
				<form>
					<h1 className="main-heading">
						<Trans>login</Trans>
					</h1>
					<div className="form-group row theme-form-email">
						<label htmlFor="staticEmail" className="col-sm-3 col-form-label">
							<Trans>lang_key_username</Trans>/<Trans>lang_key_email</Trans>
						</label>
						<div className="col-sm-9">
							<input type="text" data-test="sign-in-username-input" name="username" onChange={onChangeHandle} className="form-control" id="staticEmail" placeholder={`${t("lang_key_username")}/${t("lang_key_email")}`} />
						</div>
						{errorHtml(errors.username)}
					</div>
					<div className="row submit">
						<button onClick={onSubmit} data-test="sign-in-sign-in-button" type="button" className="btn">
							<Trans>login</Trans>
						</button>
					</div>
					<SocialLogin />
					<div className="register-links text-center">
						<a href="/register">
							<Trans>or click here to register your account</Trans>.
						</a>
					</div>
					<div className="forgot-pass-links text-center">
						<a href="/forget-password">
							<Trans>forgot password</Trans>?
						</a>
					</div>
				</form>
			</Card>
			<Modal show={showSecondFactorPopup}>
				<Modal.Body>{showSecondFactorPopup ? <SecondFactorAuth otpLen={6} waitingTime={120} data={secondFactorAuthData} /> : ""}</Modal.Body>
			</Modal>
		</div>
	);
};

export default connect(null, { login })(IsLoadingHOC(Login, "Wait ...."));
