import React, { Fragment } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import TierInformation from "../TierInformation";

export default ({ children }) => (
	<Fragment>
		<Header />
		{children}
		<Footer />
		<TierInformation />
	</Fragment>
);
