import React from "react";
import { useEffect, useState } from "react";
const LoadExternalScriptFile = (props) => {
	let [state, setState] = useState(props.url ? "loading" : "idle");
	useEffect(() => {
		if (!props.url) {
			setState("idle");
			return;
		}
		let script = document.querySelector(`script[src="${props.url}"]`);

		const handleScript = (e) => {
			setState(e.type === "load" ? "ready" : "error");
		};

		if (!script) {
			script = document.createElement("script");
			script.type = "application/javascript";
			script.src = props.url;
			script.async = true;
			document.body.appendChild(script);
			script.addEventListener("load", handleScript);
			script.addEventListener("error", handleScript);
		}

		script.addEventListener("load", handleScript);
		script.addEventListener("error", handleScript);

		return () => {
			script.removeEventListener("load", handleScript);
			script.removeEventListener("error", handleScript);
		};
	}, [props.url]);
	useEffect(() => {
		if (state === "ready") {
			props.parentCallback();
		}
	}, [state]);
	return <></>;
};
export default LoadExternalScriptFile;
