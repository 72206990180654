import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { connect, useSelector } from "react-redux";
import { spinAction } from "../../redux/actions";
import Winwheel from "winwheel";
import { toast } from "react-toastify";
const WheelEarn = (props) => {
	const contestData = props.data;
	const user = useSelector(({ user }) => user);
	const { t } = useTranslation();
	const [segments, setSegments] = useState([]);
	const [wheelSpinning, setWheelSpinning] = useState(false);
	const [parentId, setParentId] = useState("");
	const [showBalancePopup, setShowBalancePopup] = useState(false);
	const [showSpinResultPopup, setShowSpinResultPopup] = useState(false);
	const [wheelResult, setWheelResult] = useState(null);
	useEffect(() => {
		if (contestData.data) {
			let data = contestData.data.map((v) => {
				return { text: v.text, segmentId: v.segmentId, fillStyle: v.bgColor, textFillStyle: v.textColor ? v.textColor : "#000", segmentType: v.segmentType };
			});
			setSegments(data);
		}
		if (user.pointBalance < contestData.pointRequired) {
			setShowBalancePopup(true);
		}
	}, [props]);
	const setLoading = props.setLoading;
	let { id } = useParams();
	window.alertPrize = async (indicatedSegment) => {
		let data = {
			parentId: parentId,
		};
		if (props.urlType === "public") {
			data["token"] = id;
			data["contestHistoryId"] = parentId;
		} else {
			data["contestId"] = id;
		}
		props
			.spinAction(data, "put", props.urlType)
			.then((responseData) => {
				setWheelSpinning(false);
				setWheelResult(JSON.parse(JSON.stringify(indicatedSegment)));
				setParentId("");
				if (responseData.httpCode >= 200 && responseData.httpCode <= 299) {
					setShowSpinResultPopup(true);
					if (responseData.httpCode === 206) {
						setParentId(responseData.data.parentId);
					}
				} else if (responseData.httpCode === 406) {
					toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
					setShowBalancePopup(true);
				} else if (responseData.httpCode === 403) {
					toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
					setShowBalancePopup(true);
				} else {
					toast.error(`${t(`Something went wrong`)}`);
				}
				setLoading(false);
			})
			.catch((err) => {
				setWheelSpinning(false);
				if (typeof err === "object") {
					if (err.httpCode === 406) {
						toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
						setShowBalancePopup(true);
					} else if (err.httpCode === 403) {
						toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
						setShowBalancePopup(true);
					} else {
						toast.error(`${t(`Something went wrong`)}`);
					}
				} else {
					toast.error(`${t(`Something went wrong`)}`);
				}
			});
	};

	let theWheel = new Winwheel({
		numSegments: segments.length,
		outerRadius: 225,
		textFontSize: 12,
		segments: segments,
		rotationAngle: 0,
		textAlignment: "outer",
		textFontWeight: "bold",
		// Specify the animation to use.
		animation: {
			type: "spinToStop",
			duration: 5, // Duration in seconds.
			spins: 8, // Number of complete spins.
			easing: "Power4.easeOut",
			yoyo: false,
			repeat: 0,
			callbackFinished: "window.alertPrize(winwheelToDrawDuringAnimation.getIndicatedSegment())",
		},
	});
	window.theWheel = theWheel;
	const startWheel = (e) => {
		setWheelSpinning(true);
		let data = {
			parentId: parentId,
		};
		if (props.urlType === "public") {
			data["token"] = id;
			data["contestId"] = contestData.id;
		} else {
			data["contestId"] = id;
		}
		props
			.spinAction(data, "post", props.urlType)
			.then((responseData) => {
				if (responseData.httpCode === 406) {
					toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
					setShowBalancePopup(true);
					setWheelSpinning(false);
				} else if (responseData.httpCode === 403) {
					toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
					window.location.reload();
					setWheelSpinning(false);
				} else if (responseData.httpCode === 201) {
					setParentId(responseData.data.id);
					let segmentInd = segments.findIndex((v) => v.segmentId === responseData.data.segmentId);
					if (wheelSpinning == false) {
						//define Stop angle start
						let segmentAngle = 360 / segments.length;
						let stopAt = segmentAngle * segmentInd + segmentAngle / 2;
						theWheel.animation.stopAngle = stopAt;
						//define Stop angle End
						theWheel.startAnimation();
						setWheelSpinning(true);
					}
				} else {
					toast.error(`${t(`Something went wrong`)}.`);
					setWheelSpinning(false);
				}
			})
			.catch((err) => {
				setWheelSpinning(false);
				if (typeof err === "object") {
					if (err.httpCode === 406) {
						toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
						setShowBalancePopup(true);
					} else if (err.httpCode === 403) {
						toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
						window.location.reload();
					}
				} else {
					console.log("Response Error:", JSON.stringify(err));
					setLoading(false);
					toast.error(`${t(`Something went wrong`)}.`);
				}
			});
	};
	const resetWheel = () => {
		theWheel.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
		theWheel.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
		theWheel.draw();
		setWheelSpinning(false);
		setWheelResult(null);
		setShowSpinResultPopup(false);
	};
	return (
		<Container className="portal-point-list-container">
			<Container fluid>
				<div className="label">
					<Link id="headerParentLink" className="parent-link" to="/contest">
						<Trans>Contests</Trans>
					</Link>{" "}
					<b>Spin Wheel</b>
				</div>
				<Container>
					<Row xs={1} md={2} lg={2}>
						<Col>
							<div style={{ display: "flex", flexDirection: "row", alignSelf: "center", position: "relative", width: "100%", justifyContent: "center" }}>
								<canvas data-responsivemargin="600" data-responsiveminwidth="380" data-responsivescaleheight="true" height="450" id="canvas" width="550">
									<h3 className="text-center" style={{ color: "white" }}>
										{" "}
										Sorry, your browser doesn't support canvas. Please try another browser.
									</h3>
								</canvas>
								<img style={{ position: "absolute", top: "-20px", zIndex: 10 }} alt="pointer" src="https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/assets/contests/pointer.png"></img>
							</div>
						</Col>
						<Col>
							<Row md={1}>
								<Col>
									<b className="uppercase">
										<a className="">
											<Trans>Complete this</Trans>
										</a>{" "}
										{contestData["name"] ? contestData.name : ""} <Trans>and get</Trans> {contestData["pointRequired"] ? contestData.pointRequired : 0} <Trans>Points</Trans>
									</b>
									<p className="description">{contestData["description"] ? contestData.description : ""}</p>
								</Col>
								<Col className="text-center">
									<Col className="text-center" md={10}>
										<br />
										{contestData.allowSpin || user.pointBalance >= contestData.pointRequired ? (
											<Button variant="primary" disabled={wheelSpinning} onClick={startWheel}>
												{wheelSpinning ? <Spinner animation="border" variant="light" /> : <Trans>Spin</Trans>}
											</Button>
										) : (
											<span style={{ color: "#49a5fa" }}>
												<Trans>You have reached the maximum limit</Trans>.
											</span>
										)}
									</Col>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Container>
			<Modal show={showBalancePopup}>
				<Modal.Body>
					<Container>
						<Row>
							<Col xs={12} md={12} className="text-center">
								<img src="/public/webstatic/images/vip-modal.png" width="200" />
							</Col>
							<Col xs={12} md={12} className="text-center">
								<p>
									<Trans>Oops</Trans>! <Trans>Low On Points</Trans>
								</p>
								<p>
									<Trans>Unlock With More Points</Trans>
								</p>
								<p className="text-center">
									<Link to="/earnpoints" className="btn btn-primary">
										<Trans>Earn Points</Trans>
									</Link>
								</p>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>
			<Modal show={showSpinResultPopup} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Body>
					<Container>
						<Row>
							<Col xs={12} md={12} className="text-center">
								<img src="/public/webstatic/images/vip-modal.png" width="200" />
							</Col>
							<Col xs={12} md={12} className="text-center">
								<p>{wheelResult && wheelResult.segmentType === "sorry" ? `${t("Oops")}! ${t("Sorry")} ${t("better luck next time")}` : `${t("You won")} ${wheelResult ? wheelResult.text : ""}`}</p>
								<p className="text-center">
									{wheelResult && wheelResult.segmentType === "free_spin" ? (
										<Button variant="primary" onClick={resetWheel}>
											<Trans>Spin Now</Trans>
										</Button>
									) : (
										<Link variant="warning" id="back-btn" className="btn btn-warning" to="/contest">
											<Trans>GO BACK</Trans>
										</Link>
									)}
								</p>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>
		</Container>
	);
};
export default React.memo(connect(null, { spinAction })(IsLoadingHOC(WheelEarn, "Loading....")));
